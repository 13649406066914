import ImageOpt from './ImageOpt';
import { motion, useInView } from 'framer-motion';
import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { useFlexSearch } from 'react-use-flexsearch';
import { Autoplay, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuidv4 } from 'uuid';

const PopularDestination = ({ module }) => {
  const handleNextClickStay = () => {
    const swiper = document.querySelector(
      '.destinations-slider-row .swiper'
    ).swiper;
    swiper.slideNext();
  };

  const handlePrevClickStay = () => {
    const swiper = document.querySelector(
      '.destinations-slider-row .swiper'
    ).swiper;
    swiper.slidePrev();
  };

  const [currentSlide, setCurrentSlide] = useState(0);
  const [queryParams, setQueryParams] = useState(null);
  const [searchData, setsearchData] = useState([]);
  let params = {};

  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  const container2 = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.8,
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  if (typeof window !== 'undefined') {
    params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
  }

  useEffect(() => {
    setQueryParams(params.q);
  }, [params]);

  const query = useStaticQuery(graphql/* GraphQL */ `
    query {
      localSearchPages {
        index
        store
      }
    }
  `);

  const results = useFlexSearch(
    queryParams,
    query.localSearchPages.index,
    query.localSearchPages.store
  );

  useEffect(() => {
    setsearchData(results);
  }, [queryParams]);

  return (
    <>
      {' '}
      {((queryParams && searchData.length === 0) || queryParams === null) && (
        <section
          className={`destinations-slider py-60${
            !module?.backgroundStyle ||
            module?.backgroundStyle === 'background with pattern'
              ? ' bg-pattern'
              : ''
          }${module?.extraClass ? ' ' + module.extraClass : ''}`}
        >
          <div className="container-fluid pr-0">
            <motion.div
              ref={ref}
              initial="hidden"
              animate={isInView || hasPlayed ? 'show' : 'hidden'}
              variants={container}
              className="section-title fade-ani"
            >
              {module?.heading && (
                <motion.div variants={item} className="title-black">
                  <h3>{module?.heading}</h3>
                </motion.div>
              )}
              {module?.subHeading && (
                <motion.div variants={item} className="content ">
                  <p>{module.subHeading}</p>
                </motion.div>
              )}
            </motion.div>
            <motion.div
              initial="hidden"
              animate={isInView || hasPlayed ? 'show' : 'hidden'}
              variants={container2}
              id="destinationsSliderRow"
              className="destinations-slider-row destinations-drag-section cursor-hide lg:mt-50 mt-25 relative overflow-hidden"
            >
              {module.destinations?.length > 0 && (
                <>
                  <Swiper
                    spaceBetween={20}
                    loop={true}
                    lazy={{
                      loadPrevNext: true,
                    }}
                    slidesPerView={2.7}
                    centeredSlides={false}
                    allowTouchMove={true}
                    pagination={{
                      el: '.destinations-slider-pagination',
                      type: 'progressbar',
                    }}
                    speed={1000}
                    onSlideChange={(swiperCore) => {
                      const { activeIndex } = swiperCore;
                      setCurrentSlide(activeIndex);
                    }}
                    navigation={false}
                    modules={[Autoplay, Pagination, Scrollbar]}
                    breakpoints={{
                      100: {
                        slidesPerView: 1.5,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2.7,
                        spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 2.7,
                        spaceBetween: 20,
                      },
                    }}
                  >
                    {module.destinations.map((destination) => (
                      <SwiperSlide key={uuidv4()}>
                        <Link
                          to={destination?.uri}
                          className="hover:cursor-grab"
                        >
                          <div className="ds-slider-content relative h-[36vw] lgscreen:h-[45vw] mdscreen:h-[80vw]">
                            {destination?.destinationContent?.meta
                              ?.portraitFeaturedImage && (
                              <ImageOpt
                                imgData={
                                  destination?.destinationContent?.meta
                                    ?.portraitFeaturedImage.mediaItemUrl
                                }
                                width={495}
                                height={518}
                                imgAlt={
                                  destination?.destinationContent?.meta
                                    ?.portraitFeaturedImage?.altText
                                }
                                imgClass="h-[36vw] lgscreen:h-[45vw] mdscreen:h-[80vw] w-full object-cover"
                              />
                            )}
                            <div className="ds-content absolute bottom-30 smscreen:bottom-10 w-full text-center text-white z-9">
                              {destination?.title && (
                                <h3 className="lg:text-50 xlscreen:text-30 xlscreen:leading-40 lgscreen:text-30 mdscreen:text-21 mdscreen:leading-[26px] text-white uppercase hover:cursor-pointer px-15">
                                  {destination.title}
                                </h3>
                              )}
                              <div className="btn-custom btn-ignore destinations-btn-ignore  flex flex-wrap items-start justify-center space-x-8">
                                <div className="p-20 ignore-btn">
                                  <span className="button banner-btn-transparent hover:cursor-pointer">
                                    Explore
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="swiper-arrow swiper-arrow-red">
                    <a
                      className="button-next cursor-pointer absolute top-[42%] right-[15vw]  mdscreen:right-[30px] translate-y-minus_50 z-9"
                      onClick={handleNextClickStay}
                    >
                      <img
                        src="/images/white-arrow.svg"
                        loading="lazy"
                        alt="Timeless Africa Safaris image"
                      />
                    </a>
                  </div>
                  <div className="trip-slider__bottom-bar mt-10 mdscreen:mt-0 pr-70 lgscreen:pr-30">
                    <div className="trip-slider__scrollbar relative flex-1 overflow-hidden left-0 bottom-0 h-[2px]">
                      {/* <!-- swiper progressbar --> */}
                      <div className="slider-progressbar destinations-slider-pagination swiper-pagination"></div>
                    </div>
                  </div>
                </>
              )}
            </motion.div>
          </div>
        </section>
      )}
    </>
  );
};

export default PopularDestination;
export const PopularDestinationFragment = graphql`
  fragment PopularDestinationFragment on WpPage_Pagecontent_PageContent_PopularDestination {
    id
    extraClass
    hideSection
    heading
    subHeading
    destinations {
      ... on WpDestination {
        id
        title
        uri
        destinationContent {
          meta {
            portraitFeaturedImage {
              altText
              mediaItemUrl
              gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
            }
          }
        }
        subDestinations: wpChildren {
          nodes {
            ... on WpDestination {
              id
              title
              uri
            }
          }
        }
      }
    }
  }
`;
export const ExperienceSinglePopularDestinationFragment = graphql`
  fragment ExperienceSinglePopularDestinationFragment on WpExperience_Experiencecontent_Experience_PopularDestination {
    id
    extraClass
    hideSection
    heading
    subHeading
    destinations {
      ... on WpDestination {
        id
        title
        uri
        destinationContent {
          meta {
            portraitFeaturedImage {
              altText
              mediaItemUrl
              gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
            }
          }
        }
        subDestinations: wpChildren {
          nodes {
            ... on WpDestination {
              id
              title
              uri
            }
          }
        }
      }
    }
  }
`;
export const TravelStyleSinglePopularDestinationFragment = graphql`
  fragment TravelStyleSinglePopularDestinationFragment on WpTravel_Travelstylecontent_TravelStyle_PopularDestination {
    id
    extraClass
    hideSection
    backgroundStyle
    heading
    subHeading
    destinations {
      ... on WpDestination {
        id
        title
        uri
        destinationContent {
          meta {
            portraitFeaturedImage {
              altText
              mediaItemUrl
              gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
            }
          }
        }
        subDestinations: wpChildren {
          nodes {
            ... on WpDestination {
              id
              title
              uri
            }
          }
        }
      }
    }
  }
`;
